// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "scriptureplan-20181125150910--hostingbucket-master",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://scriptureplan-20181125150910--hostingbucket-master.s3-website-us-east-1.amazonaws.com",
    "aws_appsync_graphqlEndpoint": "https://weoj7ahnwbaldhzj64uwml5emi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1"
};


export default awsmobile;
