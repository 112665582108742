import React from "react";
import { NavLink } from "react-router-dom";

const NavBar = () => (
  <div data-tea="navbar" className="ui fixed secondary menu">
    <div className="ui container">
      <div className="header item" style={{ paddingLeft: 0, marginLeft: 0 }}>
        <i className="book icon" />
        Scripture Plan
      </div>
      <NavLink to="/" exact className="item">
        Home
      </NavLink>
      <NavLink to="/plans" className="item">
        Plans
      </NavLink>
      <div className="ui simple dropdown item">
        Devotions
        <i className="dropdown icon" />
        <div className="menu">
          <a className="item" href="#">
            One
          </a>
          <div className="divider" />
          <a className="item" href="#">
            Two
          </a>
        </div>
      </div>
      <div className="right menu">
        <div className="borderless item">Org Name</div>
      </div>
    </div>
  </div>
);

export default NavBar;
