import React, { Component } from "react";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { getPlan } from "../graphql/queries";
import AllDevotions from "./AllDevotions";

class ViewPlan extends Component {
  render() {
    const {
      match: {
        params: { id },
      },
      location,
    } = this.props;
    const query =
      location && location.search && new URLSearchParams(location.search);
    return (
      <Query
        query={gql(getPlan)}
        variables={{ id }}
        fetchPolicy="cache-and-network"
      >
        {({ loading, error, data }) => {
          if (error) return <span>error</span>;
          if (loading || !data) return <span>loading....</span>;

          return (
            <div>
              <h1 className="ui dividing header">{data.getPlan.name}</h1>
              <Link
                to={{
                  pathname: `/devotion/new/${data.getPlan.id}`,
                  state: {
                    planId: data.getPlan.id,
                    planName: data.getPlan.name,
                  },
                }}
                className="ui button green"
              >
                Add Devotion
              </Link>
              <br />
              {query && query.get("saved") && (
                <div className="ui positive message">Devotion saved!</div>
              )}
              <br />
              <AllDevotions devotions={data.getPlan.devotions} />
            </div>
          );
        }}
      </Query>
    );
  }
}

export default ViewPlan;
