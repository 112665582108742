import React, { Component } from "react";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import { graphql, compose, withApollo } from "react-apollo";
import { listPlans } from "../graphql/queries";

class AllPlans extends Component {
  static defaultProps = {
    plans: [],
  };

  planCard(plan) {
    return (
      <div className="ui card" key={plan.id}>
        <div className="content">
          <div className="header">{plan.name}</div>
        </div>
        <div className="content">
          <h4 className="ui sub header">365 Day Plan</h4>
          <div className="ui small feed">
            <div className="event">
              <div className="content">
                <div className="summary">
                  January 1, 2019 - December 31, 2019
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="extra content">
          <Link to={`/plan/${plan.id}`} className="ui button">
            Edit Plan
          </Link>
          <Link
            to={{
              pathname: `/devotion/new/${plan.id}`,
              state: {
                planId: plan.id,
                planName: plan.name,
              },
            }}
            className="ui button"
          >
            Add Devotion
          </Link>
        </div>
      </div>
    );
  }

  render() {
    const { plans, location } = this.props;
    const query =
      location && location.search && new URLSearchParams(location.search);

    return (
      <div>
        <h1 className="ui dividing header">All Plans</h1>
        <br />
        {query && query.get("saved") && (
          <div className="ui positive message">Plan saved!</div>
        )}
        <br />

        <Link to="/plans/new" className="ui button green">
          Create Plan
        </Link>
        <br />
        <br />
        <div className="ui stackable four cards">
          {[].concat(plans).map(plan => this.planCard(plan))}
        </div>
      </div>
    );
  }
}

export default withApollo(
  compose(
    graphql(gql(listPlans), {
      options: {
        fetchPolicy: "cache-and-network",
      },
      props: ({ data: { listPlans = { items: [] } } }) => ({
        plans: listPlans.items,
      }),
    }),
  )(AllPlans),
);
