// eslint-disable
// this is an auto generated file. This will be overwritten

export const createPlan = `mutation CreatePlan($input: CreatePlanInput!) {
  createPlan(input: $input) {
    id
    name
    devotions {
      items {
        id
        title
        dateScheduled
        comment
        scriptures
        created
      }
      nextToken
    }
    created
  }
}
`;
export const updatePlan = `mutation UpdatePlan($input: UpdatePlanInput!) {
  updatePlan(input: $input) {
    id
    name
    devotions {
      items {
        id
        title
        dateScheduled
        comment
        scriptures
        created
      }
      nextToken
    }
    created
  }
}
`;
export const deletePlan = `mutation DeletePlan($input: DeletePlanInput!) {
  deletePlan(input: $input) {
    id
    name
    devotions {
      items {
        id
        title
        dateScheduled
        comment
        scriptures
        created
      }
      nextToken
    }
    created
  }
}
`;
export const createDevotion = `mutation CreateDevotion($input: CreateDevotionInput!) {
  createDevotion(input: $input) {
    id
    title
    dateScheduled
    plan {
      id
      name
      created
    }
    comment
    scriptures
    created
  }
}
`;
export const updateDevotion = `mutation UpdateDevotion($input: UpdateDevotionInput!) {
  updateDevotion(input: $input) {
    id
    title
    dateScheduled
    plan {
      id
      name
      created
    }
    comment
    scriptures
    created
  }
}
`;
export const deleteDevotion = `mutation DeleteDevotion($input: DeleteDevotionInput!) {
  deleteDevotion(input: $input) {
    id
    title
    dateScheduled
    plan {
      id
      name
      created
    }
    comment
    scriptures
    created
  }
}
`;
