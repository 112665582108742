import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import "react-datepicker/dist/react-datepicker.css";

import appSyncConfig from "./aws-exports";
import { ApolloProvider } from "react-apollo";
import AWSAppSyncClient from "aws-appsync";
import { Rehydrated } from "aws-appsync-react";

import AllPlans from "./components/AllPlans";
import NewPlan from "./components/NewPlan";
import ViewPlan from "./components/ViewPlan";
import NewDevotion from "./components/NewDevotion";
import NavBar from "./components/NavBar";
import "./App.css";

const Home = () => (
  <div data-tea="home">
    <AllPlans />
  </div>
);

const App = () => (
  <Router>
    <React.Fragment>
      <NavBar />
      <div className="ui container">
        <Route exact={true} path="/" component={Home} />
        <Route exact path="/plans" component={AllPlans} />
        <Route path="/plans/new" component={NewPlan} />
        <Route path="/plan/:id" component={ViewPlan} />
        <Route path="/devotion/new/:planId" component={NewDevotion} />
      </div>
    </React.Fragment>
  </Router>
);

const client = new AWSAppSyncClient({
  url: appSyncConfig.aws_appsync_graphqlEndpoint,
  region: appSyncConfig.aws_appsync_region,
  auth: {
    type: appSyncConfig.aws_appsync_authenticationType,
    apiKey: appSyncConfig.aws_appsync_apiKey,
  },
});

const WithProvider = () => (
  <ApolloProvider client={client}>
    <Rehydrated>
      <App />
    </Rehydrated>
  </ApolloProvider>
);

export default WithProvider;
