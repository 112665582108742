// eslint-disable
// this is an auto generated file. This will be overwritten

export const getPlan = `query GetPlan($id: ID!) {
  getPlan(id: $id) {
    id
    name
    devotions {
      items {
        id
        title
        dateScheduled
        comment
        scriptures
        created
      }
      nextToken
    }
    created
  }
}
`;
export const listPlans = `query ListPlans(
  $filter: ModelPlanFilterInput
  $limit: Int
  $nextToken: String
) {
  listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      devotions {
        items {
          id
          title
          dateScheduled
          comment
          scriptures
          created
        }
        nextToken
      }
      created
    }
    nextToken
  }
}
`;
export const getDevotion = `query GetDevotion($id: ID!) {
  getDevotion(id: $id) {
    id
    title
    dateScheduled
    plan {
      id
      name
      created
    }
    comment
    scriptures
    created
  }
}
`;
export const listDevotions = `query ListDevotions(
  $filter: ModelDevotionFilterInput
  $limit: Int
  $nextToken: String
) {
  listDevotions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      dateScheduled
      plan {
        id
        name
        created
      }
      comment
      scriptures
      created
    }
    nextToken
  }
}
`;
