import React, { Component } from "react";
import { withFormik, Field } from "formik";
import gql from "graphql-tag";
import { graphql, compose } from "react-apollo";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { createDevotion } from "../graphql/mutations";

class NewDevotion extends Component {
  static defaultProps = {
    createDevotion: () => null,
  };

  render() {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      location,
      history,
    } = this.props;
    const { planName } = location.state;
    console.log("ERRORs: ", errors, Object.keys(errors));
    return (
      <div className="ui container">
        <h1 className="ui header">
          {planName ? planName : undefined} > New Devotion
        </h1>
        <div className="ui vertically divided grid">
          <div className="two column row">
            <div className="column">
              {errors && Object.keys(errors).length > 1 && (
                <div className="ui error message">
                  <div className="header">Required Fields</div>
                  <p>Please fix the required fields.</p>
                </div>
              )}
              <form
                className="ui form"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <div
                  className={
                    errors.dateScheduled && touched.dateScheduled
                      ? "field error"
                      : "field"
                  }
                >
                  <label>Date Scheduled</label>
                  <DatePicker
                    name="dateScheduled"
                    selected={values["dateScheduled"]}
                    onChange={e => setFieldValue("dateScheduled", e)}
                    minDate={new Date()}
                    placeholderText="Select a date"
                  />
                </div>
                <div className="field">
                  <label>Title (Optional)</label>
                  <Field
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    name="title"
                  />
                </div>
                <div
                  className={
                    errors.scriptureRefs && touched.scriptureRefs
                      ? "field error"
                      : "field"
                  }
                >
                  <label>Scripture References (separate by comma)</label>
                  <Field
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.scriptureRefs}
                    name="scriptureRefs"
                    placeholder="e.g. Genesis 1:1-10, Psalm 100, Psalm 23:1"
                  />
                </div>

                <div
                  className={
                    errors.comment && touched.comment ? "field error" : "field"
                  }
                >
                  <label>Devotional Content</label>
                  <Field
                    component="textarea"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.comment}
                    name="comment"
                  />
                </div>
                <div className="extra">
                  <button
                    className="ui button"
                    type="button"
                    onClick={history.goBack}
                  >
                    Cancel
                  </button>
                  <button
                    className="ui right floated button green"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
            <div className="column">
              <div className="ui raised card fluid">
                <div className="content">
                  <div className="ui sub header">Devotion Live Preview</div>
                </div>
                <div className="content">
                  <h2 className="ui header">{values.title || "Title"}</h2>
                  <h3 className="ui header">
                    Scheduled: &nbsp;&nbsp;&nbsp;{" "}
                    {(values.dateScheduled &&
                      format(values.dateScheduled, "YYYY-MM-DD")) ||
                      "N/A"}
                  </h3>
                  <div>
                    {values.scriptureRefs && values.scriptureRefs.split(",")}
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: values.comment }} />
                </div>
                <div className="extra content">
                  <button className="ui button">Preview Page</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  graphql(gql(createDevotion)),
  withFormik({
    mapPropsToValues: () => ({
      title: "",
      dateScheduled: undefined,
      scriptureRefs: "",
      comment: "",
    }),
    validateOnChange: false,
    // Custom sync validation
    validate: values => {
      const errors = {};

      if (!values.dateScheduled) {
        errors.dateScheduled = "Required";
      }

      if (!values.scriptureRefs) {
        errors.scriptureRefs = "Required";
      }

      if (!values.comment) {
        errors.comment = "Required";
      }

      return errors;
    },

    handleSubmit: (values, { props, setSubmitting }) => {
      //console.log("values", values);
      const scriptures = values.scriptureRefs
        ? values.scriptureRefs.split(",")
        : [];

      const variables = {
        input: {
          title: values.title,
          dateScheduled: values.dateScheduled,
          scriptures,
          comment: values.comment,
          devotionPlanId: props.match.params.planId,
        },
      };
      props
        .mutate({ variables })
        .then(() =>
          props.history.push(`/plan/${props.match.params.planId}?saved=true`),
        )
        .then(() => console.log("Saved!", variables));
    },

    displayName: "New Devotion",
  }),
)(NewDevotion);
