import React, { Component } from "react";
import { format } from "date-fns";

class AllDevotions extends Component {
  devCard(dev) {
    return (
      <div className="ui item" key={dev.id}>
        <div className="content">
          <div className="header">{dev.title}</div>
          <h4 className="ui sub header">
            {format(dev.dateScheduled, "YYYY-MM-DD")}
          </h4>
        </div>
        <div className="description">
          <p />
        </div>
        <div className="extra">
          <div className="ui right floated button">Action</div>
        </div>
      </div>
    );
  }

  render() {
    const { devotions } = this.props;
    return (
      <div className="ui items">
        {[]
          .concat(devotions.items)
          .sort((a, b) => a.dateScheduled.localeCompare(b.dateScheduled))
          .map(dev => this.devCard(dev))}
      </div>
    );
  }
}

export default AllDevotions;
