import React, { Component } from "react";
import { withFormik, Field } from "formik";

import gql from "graphql-tag";
import { graphql, compose } from "react-apollo";
import { createPlan } from "../graphql/mutations";

class NewPlan extends Component {
  static defaultProps = {
    createPlan: () => null,
  };

  render() {
    const {
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
    } = this.props;
    return (
      <div className="ui container">
        <h1 className="ui header">New Plan</h1>
        <div className="ui vertically divided grid">
          <div className="two column row">
            <div className="column">
              <form
                className="ui form"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <Field
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  name="name"
                />
                {errors.name && touched.name && (
                  <div id="feedback">{errors.name}</div>
                )}
                <br />
                <br />
                <button className="ui button" type="submit">
                  Save
                </button>
              </form>
            </div>
            <div className="column">right column</div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  graphql(gql(createPlan)),
  withFormik({
    mapPropsToValues: () => ({ name: "" }),

    // Custom sync validation
    validate: values => {
      const errors = {};

      if (!values.name) {
        errors.name = "Required";
      }

      return errors;
    },

    handleSubmit: (values, { props, setSubmitting }) => {
      //console.log("values", values);
      props
        .mutate({
          variables: { input: values },
        })
        .then(() => props.history.push("/plans?saved=true"))
        .then(() => console.log("Saved!", values));
    },

    displayName: "New Plan",
  }),
)(NewPlan);
